<template>
  <div title="List Users">
    <!-- <b-container fluid="sm" class="mb-5">
      <b-row class="text-right">
        <b-col>
          <b-button @click="createCat" variant="success">
            <plus-icon size="1.2x" class="custom-class"></plus-icon>ADD
          </b-button>
        </b-col>
      </b-row>
    </b-container> -->

    <!-- search input -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Search</label>
          <b-form-input v-model="searchTerm" placeholder="Search" type="text" class="d-inline-block" />
        </div>
      </b-form-group>
    </div>

    <!-- table -->
    <vue-good-table :columns="columns" :rows="rows" :rtl="direction" :search-options="{
      enabled: true,
      externalQuery: searchTerm
    }" :line-numbers="true" :pagination-options="{
  enabled: true,
  perPage: pageLength
}">
      <template slot="table-row" slot-scope="props">

        <!-- Column: Name -->
        <span v-if="props.column.field === 'image'" class="text-nowrap">
          <b-img :src="imgUrl(props.row.image)" class="mx-1" v-bind="mainProps"></b-img>
        </span>

        <!-- Column: Status -->
        <!-- <span v-else-if="props.column.field === 'status'">
          <b-badge :variant="statusVariant(props.row.status)">
            {{ props.row.status }}
          </b-badge>
        </span> -->

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
              <template v-slot:button-content>
                <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle mr-25" />
              </template>
              <b-dropdown-item :to="{ name: 'psetting-edit', params: { id: props.row.id } }">
                <feather-icon icon="EditIcon" class="mr-50" />
                <span>Edit</span>
              </b-dropdown-item>
              <!-- <b-dropdown-item>
                <feather-icon icon="TrashIcon" class="mr-50" />
                <span>Delete</span>
              </b-dropdown-item> -->
            </b-dropdown>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template slot="pagination-bottom" slot-scope="props">
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select v-model="pageLength" :options="['5', '10', '25']" class="mx-1"
              @input="(value) => props.perPageChanged({ currentPerPage: value })" />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number last-number
              align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0"
              @input="(value) => props.pageChanged({ currentPage: value })">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </div>
</template>

<script>

import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, BButton, BContainer, BRow, BCol, BImg
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
// import the styles
import 'vue-good-table/dist/vue-good-table.css'
import store from '@/store/index'
import { PlusIcon } from 'vue-feather-icons'
//Database
import { db } from "@/main";

export default {
  components: {
    BCardCode,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BButton,
    BContainer,
    BRow,
    BCol,
    BImg,
    PlusIcon,
  },
  data() {
    return {
      mainProps: { blank: false, width: 75, height: 75, class: 'm1' },
      pageLength: 5,
      dir: false,
      columns: [
        {
          label: 'Title Location',
          field: 'android',
        },
        {
          label: 'Title',
          field: 'ios',
          sortable: false,
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
        },
      ],
      rows: [],
      searchTerm: '',
      status: [{
        1: 'Current',
        2: 'Professional',
        3: 'Rejected',
        4: 'Resigned',
        5: 'Applied',
      },
      {
        1: 'light-primary',
        2: 'light-success',
        3: 'light-danger',
        4: 'light-warning',
        5: 'light-info',
      }],
    }
  },
  methods: {


    readEmployees() {
      store.dispatch('app/commitActivateLoader');
      
      this.rows = [];
      var i = 1;
      db.collection("psetings")
        .get()  
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.rows.push({
              index: i,
              id: doc.id,
              android: doc.data().android,
              ios: doc.data().ios,
              //description: doc.data().description,
              created: doc.data().created,
              modified: doc.data().modified,
            });
			console.log("dasdad");
            console.log(doc.id, " => ",  doc.data());
            i++;
            store.dispatch('app/commitDeactivateLoader');
          });
        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
          store.dispatch('app/commitDeactivateLoader');
        });
    },
    imgUrl(url){
      return url.length > 0 ? url : null;
    },
    deleteEmployee(id) {

if(confirm("Do you really want to delete?")){
       // alert('dddd');
      db.collection("countries")
        .doc(id)
       .delete()
        .then(() => {
          console.log("Document successfully deleted!");
          this.readEmployees();
          this.$toasted.success('Success, Deleted Successfully!', {
            icon : 'check',
             //icon : 'error_outline'
         })
         //this.$router.push('/countries/index');
          
        })
        .catch((error) => {
          console.error("Error removing document: ", error);
        });

    }
    },
  },
  mounted() {
    this.readEmployees();
  },
};
</script>